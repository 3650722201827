import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'

export function useBusinessManImage() {
	const { bgImg } = useStaticQuery(
		graphql`
			query {
				bgImg: file(relativePath: { eq: "businessman.jpg" }) {
					childImageSharp {
						gatsbyImageData(width: 1400, placeholder: BLURRED, formats: AUTO)
					}
				}
			}
		`
	)

	const convert = getImage(bgImg)
	const finalBg = convertToBgImage(convert)

	return finalBg
}
