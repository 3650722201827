import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { ConvertkitForm } from '../components/form'
import Layout from '../components/layout'
import { LoadingSection, ProductSection, Section } from '../components/section'
import SectionHero from '../components/section-hero'
import Seo from '../components/seo'
import { useBusinessManImage } from '../hooks/businessman-hook'

const EbookPage = () => {
	return (
		<Layout>
			<Seo
				title="Free Options Trading Ebook"
				description="Thank you for signing up for Rapid Fire Options free EBook."
			/>
			<SectionHero
				image={useBusinessManImage()}
				title1="FREE EBOOK"
				title2="Rapid Fire Options"
				body="Rapid Fire Options is a screener based service based on unusual activities in certain options spanning individual stocks, SPACs, ETFs, Futures, etc."
				bgPosition={{ backgroundPosition: '25% 25%' }}
			/>
			<Section sectionTitle="Claim your free options trading eBook today">
				<Fade triggerOnce>
					<div className="flex flex-col items-center justify-center w-full gap-8 text-center">
						<StaticImage
							objectPosition="center top"
							placeholder="blurred"
							objectFit="contain"
							width={200}
							src="../images/ebook-cover.png"
							alt="Image of a Options trade"
						/>
						<p>
							Options trading is by far the fastest way to turn $1,000 into
							$1,000,000. But if you're not reading the signals, trading with
							emotion, and investing at a whim, you'll turn that $1,000 into
							blood-red in no time.
						</p>
						<p>
							This <strong>free</strong> options trading eBook will guide you on
							your journey to becoming a stock options pro. And, of course, a
							wealthier individual with financial freedom!
						</p>
						<div className="w-11/12 md:w-8/12">
							<ConvertkitForm />
						</div>
					</div>
				</Fade>
			</Section>

			<LoadingSection>
				We have our finger on the
				<span className="text-primary font-bold animate-pulse"> PULSE</span> of
				the markets and are constantly updating our stock options screener..
			</LoadingSection>

			<ProductSection />
		</Layout>
	)
}

export default EbookPage
